import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Grid from "../../components/Grid";
import Layout from "../../components/Layout";
import Content from "../../components/Content";
import { HTMLContent } from "../../components/Content/Content";

export const DefaultPageTemplate = ({ seo, content, contentComponent, location }) => {
  const PostContent = contentComponent || Content;

  return (
    <Layout location={location}>
      <Helmet
        title={seo?.title}
        meta={[
          {
            name: "description",
            content: seo?.description,
          },
        ]}
      />
      <Grid container className="py-40">
        <PostContent content={content} className="body-large typography" />
      </Grid>
    </Layout>
  );
};

DefaultPageTemplate.propTypes = {
  seo: PropTypes.object,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  location: PropTypes.object,
};

DefaultPageTemplate.defaultProps = {
  contentComponent: undefined,
  location: undefined,
  seo: {},
};

const DefaultPage = ({ data, location }) => {
  const { frontmatter, html } = data?.markdownRemark || {};

  return (
    <DefaultPageTemplate seo={frontmatter?.seo} content={html} contentComponent={HTMLContent} location={location} />
  );
};

DefaultPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      timeToRead: PropTypes.number,
      html: PropTypes.node.isRequired,
    }),
  }),
  location: PropTypes.object,
};

DefaultPage.defaultProps = {
  data: {
    markdownRemark: {
      frontmatter: {},
      timeToRead: 0,
    },
  },
  location: undefined,
};

export default DefaultPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        seo {
          title
          description
        }
      }
    }
  }
`;
